import { all, call, Effect, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actionTypes from '../actionTypes';
import {
    deviceAPI,
    getAllDeviceAPI,
    getSingleDeviceGraphAPI,
    singleDeviceAPI,
    getSingleDeviceCustomRangeData,
} from 'api/deviceAPI';
import {
    fetchDeviceAppendSuccess,
    fetchDeviceFailure,
    fetchDeviceSuccess,
    fetchSingleDeviceFailure,
    fetchSingleDeviceGraphFailure,
    fetchSingleDeviceGraphSuccess,
    fetchSingleDeviceSuccess,
    getAllDeviceFailure,
    getAllDeviceSucess,
} from 'redux/actions/deviceActions';
import { DeviceResponse, GetAllDeviceResponse } from 'redux/types';
import { GraphResponse, ThingData } from 'shared/model/DeviceModel';
import { saveDeviceFilter } from 'redux/actions/deviceFilterActions';

/*
  Worker Saga: Fired on DEVICE_REQUEST action
*/
function* fetchDeviceSaga(action: Effect) {
    try {
        const isMetalmarkQuery = action.payload.isMetalmarkQuery;
        const response: DeviceResponse = yield call(deviceAPI, action.payload);
        yield put(
            fetchDeviceSuccess({
                things: response.things,
                nextToken: response.nextToken,
                count: response.count,
                isMetalmarkQuery,
            }),
        );
        yield put(saveDeviceFilter());
    } catch (e: any) {
        yield put(
            fetchDeviceFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on DEVICE_REQUEST_APPEND action
*/
function* fetchDeviceAppendSaga(action: Effect) {
    try {
        const response: DeviceResponse = yield call(deviceAPI, action.payload);
        yield put(
            fetchDeviceAppendSuccess({
                things: response.things,
                nextToken: response.nextToken,
                count: response.count,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchDeviceFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on SINGLE_DEVICE_REQUEST action
*/
function* fetchSingleDeviceSaga(action: Effect) {
    try {
        const response: ThingData = yield call(singleDeviceAPI, action.payload.id);
        yield put(
            fetchSingleDeviceSuccess({
                thing: response,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchSingleDeviceFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on SINGLE_DEVICE_GRAPH_REQUEST action
*/
function* fetchSingleDeviceGraphSaga(action: Effect) {
    try {
        const response: GraphResponse = yield call(
            getSingleDeviceGraphAPI,
            action.payload.id,
            action.payload.timestamp,
        );
        yield put(
            fetchSingleDeviceGraphSuccess({
                graphResponse: response,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchSingleDeviceGraphFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on SINGLE_DEVICE_CUSTOM_RANGE_REQUEST action
*/
function* fetchSingleDeviceCustomRangeSaga(action: Effect) {
    try {
        const response: GraphResponse = yield call(
            getSingleDeviceCustomRangeData,
            action.payload.id,
            action.payload.startTime,
            action.payload.endTime,
        );
        yield put(
            fetchSingleDeviceGraphSuccess({
                graphResponse: response,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchSingleDeviceGraphFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on GET_ALL_DEVICES action
*/
function* getAllDeviceSaga(action: Effect) {
    try {
        const { orgId } = action.payload;
        const response: GetAllDeviceResponse = yield call(getAllDeviceAPI, orgId);
        yield put(
            getAllDeviceSucess({
                things: response.things,
                count: 0,
            }),
        );
    } catch (e: any) {
        yield put(
            getAllDeviceFailure({
                error: e.message,
            }),
        );
    }
}

function* deviceSaga() {
    yield all([takeEvery(actionTypes.DEVICE_REQUEST, fetchDeviceSaga)]);
    yield all([takeLatest(actionTypes.DEVICE_REQUEST_APPEND, fetchDeviceAppendSaga)]);
    yield all([takeLatest(actionTypes.SINGLE_DEVICE_REQUEST, fetchSingleDeviceSaga)]);
    yield all([takeLatest(actionTypes.SINGLE_DEVICE_GRAPH_REQUEST, fetchSingleDeviceGraphSaga)]);
    yield all([takeLatest(actionTypes.GET_ALL_DEVICES, getAllDeviceSaga)]);
    yield all([takeLatest(actionTypes.SINGLE_DEVICE_CUSTOM_RANGE_REQUEST, fetchSingleDeviceCustomRangeSaga)]);
}

export default deviceSaga;

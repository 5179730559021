export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';
export const FETCH_DASHBOARD_PERFORMANCE_REQUEST = 'FETCH_DASHBOARD_PERFORMANCE_REQUEST';
export const FETCH_DASHBOARD_PERFORMANCE_SUCCESS = 'FETCH_DASHBOARD_PERFORMANCE_SUCCESS';
export const FETCH_DASHBOARD_PERFORMANCE_FAILURE = 'FETCH_DASHBOARD_PERFORMANCE_FAILURE';
export const FETCH_DASHBOARD_PERFORMANCE_GRAPH_REQUEST = 'FETCH_DASHBOARD_PERFORMANCE_GRAPH_REQUEST';
export const FETCH_DASHBOARD_PERFORMANCE_GRAPH_SUCCESS = 'FETCH_DASHBOARD_PERFORMANCE_GRAPH_SUCCESS';
export const FETCH_DASHBOARD_PERFORMANCE_GRAPH_FAILURE = 'FETCH_DASHBOARD_PERFORMANCE_GRAPH_FAILURE';
export const FETCH_DASHBOARD_PERFORMANCE_BAR_REQUEST = 'FETCH_DASHBOARD_PERFORMANCE_BAR_REQUEST';
export const FETCH_DASHBOARD_PERFORMANCE_BAR_SUCCESS = 'FETCH_DASHBOARD_PERFORMANCE_BAR_SUCCESS';
export const FETCH_DASHBOARD_PERFORMANCE_BAR_FAILURE = 'FETCH_DASHBOARD_PERFORMANCE_BAR_FAILURE';
export const DASHBOARD_PERFORMANCE_GRAPH_LOADING = 'DASHBOARD_PERFORMANCE_GRAPH_LOADING';
export const FETCH_DASHBOARD_REQUEST_FROM_CARD = 'FETCH_DASHBOARD_REQUEST_FROM_CARD';
export const FETCH_DASHBOARD_REQUEST_FROM_CARD_SUCCESS = 'FETCH_DASHBOARD_REQUEST_FROM_CARD_SUCCESS';
export const FETCH_DASHBOARD_REQUEST_FROM_CARD_FAILURE = 'FETCH_DASHBOARD_REQUEST_FROM_CARD_FAILURE';
export const FETCH_DASHBOARD_CUSTOM_RANGE_REQUEST = 'FETCH_DASHBOARD_CUSTOM_RANGE_REQUEST';
export const FETCH_DASHBOARD_CUSTOM_RANGE_SUCCESS = 'FETCH_DASHBOARD_CUSTOM_RANGE_SUCCESS';
export const FETCH_DASHBOARD_CUSTOM_RANGE_FAILURE = 'FETCH_DASHBOARD_CUSTOM_RANGE_FAILURE';

import { DeviceQuery, IDeviceUpdate } from 'shared/model/DeviceModel';
import { http_get, http_post, http_put } from './BaseURLAxios';
import { COMMANDS, CONFIRMATION, DEVICE, OPERATIONS } from './urlConstants';
import { DeviceConfirmAPI } from 'redux/types/deviceCommandModel';
import { CUSTOM_RANGE, GET_ALL_DEVICE, SITE } from 'containers/urlConstant';

export const deviceAPI = async (body: DeviceQuery) => {
    delete body?.save_last_query;
    delete body?.isMetalmarkQuery;
    return await http_post(DEVICE, body).then((res: any) => {
        return res;
    });
};

export const updateDevicesAPI = async (body: IDeviceUpdate) => {
    return await http_put(DEVICE, body).then((res: any) => {
        return res;
    });
};

export const singleDeviceAPI = async (id: string) => {
    return await http_get(DEVICE + '/' + id).then((res: any) => {
        return res;
    });
};

export const getSingleDeviceGraphAPI = async (id: string, timestamp: string) => {
    return await http_get(DEVICE + '/' + id + '/' + timestamp).then((res: any) => {
        return res;
    });
};

export const getSingleDeviceOperationGraphAPI = async (id: string, timestamp: string) => {
    return await http_get(DEVICE + '/' + id + '/' + OPERATIONS + '/' + timestamp).then((res: any) => {
        return res;
    });
};

export const deviceMode = async (body: { device_list: string[]; command: string; action: string }) => {
    return await http_post(DEVICE + COMMANDS, body).then((res: any) => {
        return res;
    });
};
export const deviceModeConfirmation = async (body: { command: string; device_command_id_dict: DeviceConfirmAPI }) => {
    return await http_post(DEVICE + COMMANDS + CONFIRMATION, body).then((res: any) => {
        return res;
    });
};
export const devicecommandtable = async (orgId: string) => {
    return await http_get(SITE + COMMANDS + '/' + orgId).then((res: any) => {
        return res;
    });
};

export const getAllDeviceAPI = async (orgId: number) => {
    return await http_get(GET_ALL_DEVICE, { params: { org_id: orgId } }).then((res: any) => {
        return res;
    });
};

export const getSingleDeviceCustomRangeData = async (id: string, startTime: string, endTime: string) => {
    return await http_get(DEVICE + '/' + id + CUSTOM_RANGE + '?start_time=' + startTime + '&end_time=' + endTime).then(
        (res: any) => {
            return res;
        },
    );
};

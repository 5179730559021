import {
    CUSTOMERS,
    DASHBOARD,
    DEVICES,
    MANAGE_SITE_FROM_DEVICE,
    MYORG,
    PROFILE,
    HOME,
    STYLEGUIDE,
    SITE,
    MANAGE_SITE_FROM_CUSTOMERS,
    SERVICER,
    PAGE_404,
    SETTINGS,
    MANAGE_SITE,
    MANAGE_COMMANDS_FROM_DEVICE,
    MANAGE_COMMANDS_FROM_CUSTOMERS,
    DEVICE_SITE,
    CUSTOMER_SITE,
    DEVICE_COMMAND,
    CUSTOMER_COMMAND,
    FIRMWARES,
    MANAGE_USERS,
    USERS,
} from 'containers/urlConstant';
import React from 'react';
import { SITE_HEIRARCHY_COMMAND_TEXT, VIEW_USERS } from 'shared/utils/Constants';

const Dashboard = React.lazy(() => import('./views/dashboard/Dashboard'));
const CustomerOrg = React.lazy(() => import('./views/organization/customer'));
const Devices = React.lazy(() => import('./views/devices/Device'));
const MyOrg = React.lazy(() => import('./views/myorg'));
const Profile = React.lazy(() => import('./views/profile'));
const ManageSites = React.lazy(() => import('./views/managesite'));
const StyleGuide = React.lazy(() => import('./views/styleguide/Styleguide'));
const Servicer = React.lazy(() => import('./views/organization/servicer'));
const Page404 = React.lazy(() => import('./views/auth/page404/Page404'));
const Setting = React.lazy(() => import('./views/setting/Settings'));
const Commands = React.lazy(() => import('./views/deviceCommands/component/DeviceCommands'));
const Firmware = React.lazy(() => import('./views/firmwares/component/Firmwares'));
const Users = React.lazy(() => import('./views/organization/component/ViewUsers'));

const SITE_HEIRARCHY_TEXT = 'Site Navigation';

export const common_routes = [
    { path: HOME, exact: true, name: 'Home', mainRoute: HOME },
    { path: PROFILE, name: 'Profile', component: Profile, mainRoute: PROFILE },
    { path: STYLEGUIDE, name: 'StyleGuide', component: StyleGuide, mainRoute: STYLEGUIDE },
    { path: PAGE_404, name: '404', component: Page404, mainRoute: PAGE_404 },
    { path: SETTINGS, name: 'Settings', component: Setting, mainRoute: SETTINGS },
];
export const OrgRoute = { path: MYORG, name: 'My Organization', component: MyOrg, mainRoute: MYORG };
export const DashboardRoute = { path: DASHBOARD, name: 'Dashboard', component: Dashboard, mainRoute: DASHBOARD };
export const ManageSiteRoute = {
    path: MANAGE_SITE,
    name: SITE_HEIRARCHY_TEXT,
    component: ManageSites,
    mainRoute: SITE,
    exact: true,
};
export const ManageSiteRouteFromDeviceRoute = {
    path: MANAGE_SITE_FROM_DEVICE,
    name: SITE_HEIRARCHY_TEXT,
    component: ManageSites,
    mainRoute: DEVICE_SITE,
    exact: true,
};
export const ManageCommandsFromDeviceRoute = {
    path: MANAGE_COMMANDS_FROM_DEVICE,
    name: SITE_HEIRARCHY_COMMAND_TEXT,
    component: Commands,
    mainRoute: DEVICE_COMMAND,
    exact: true,
};
export const ManageUsers = {
    path: MANAGE_USERS,
    name: VIEW_USERS,
    component: Users,
    mainRoute: USERS,
    exact: true,
};

const routes = [
    DashboardRoute,
    OrgRoute,
    ManageSiteRouteFromDeviceRoute,
    ManageCommandsFromDeviceRoute,
    {
        path: MANAGE_SITE_FROM_CUSTOMERS,
        name: SITE_HEIRARCHY_TEXT,
        component: ManageSites,
        mainRoute: CUSTOMER_SITE,
        exact: true,
    },
    {
        path: MANAGE_COMMANDS_FROM_CUSTOMERS,
        name: SITE_HEIRARCHY_COMMAND_TEXT,
        component: Commands,
        mainRoute: CUSTOMER_COMMAND,
        exact: true,
    },

    { path: DEVICES, name: 'Devices', component: Devices, mainRoute: DEVICES },
    { path: SERVICER, name: 'Servicers', component: Servicer, mainRoute: SERVICER },
];

export const super_routes = [
    ManageUsers,
    { path: CUSTOMERS, name: 'Customers', component: CustomerOrg, mainRoute: CUSTOMERS },
    { path: FIRMWARES, name: 'Firmwares', component: Firmware, mainRoute: FIRMWARES },
];
export default routes;

import {
    LoginRequest,
    LoginRequestFailure,
    LoginRequestSuccess,
    LoginResponse,
    LogoutRequest,
    LogoutRequestFailure,
    RemoveToken,
} from 'redux/types/authActionModel';
import * as actionTypes from '../actionTypes';
import { FailurePayload } from '../types';

export const loginRequest = (payload: { email: string; password: string }): LoginRequest => ({
    type: actionTypes.LOGIN_REQUEST,
    payload,
});

export const loginRequestSuccess = (payload: LoginResponse): LoginRequestSuccess => ({
    type: actionTypes.LOGIN_REQUEST_SUCCESS,
    payload,
});

export const loginRequestFailure = (payload: FailurePayload): LoginRequestFailure => ({
    type: actionTypes.LOGIN_REQUEST_FAILURE,
    payload,
});
export const logoutRequest = (): LogoutRequest => ({
    type: actionTypes.LOGOUT_REQUEST,
});

export const logoutRequestFailure = (payload: FailurePayload): LogoutRequestFailure => ({
    type: actionTypes.LOGOUT_REQUEST_FAILURE,
    payload,
});

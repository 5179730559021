import { OrganizationState, OrganizationActions } from 'redux/types';
import { OrganizationModel } from 'shared/model';
import * as actionTypes from '../actionTypes';

const initialState: OrganizationState = {
    isLoading: false,
    data: [],
    selectedOrganization: undefined,
    error: null,
    isSavingLoading: false,
    isSavingDataSuccess: false,
    isSavingDataError: null,
    organization: undefined,
    isCustomersLoading: false,
    customers: [],
};

export const orgReducer = (state = initialState, action: OrganizationActions): OrganizationState => {
    const commontype = () => {
        return { ...state, isSavingLoading: true, isSavingDataSuccess: false, isSavingDataError: null };
    };
    const fetchOrg = () => {
        return {
            ...state,
            isLoading: true,
            isSavingLoading: false,
            isSavingDataSuccess: false,
            isSavingDataError: null,
        };
    };
    const saveSelectedOrg = (org: OrganizationModel) => {
        return {
            ...state,
            selectedOrganization: org,
        };
    };
    const setCustomersLoading = () => {
        return {
            ...state,
            isCustomersLoading: true,
        };
    };
    switch (action.type) {
        case actionTypes.FETCH_ORG_REQUEST:
            return fetchOrg();

        case actionTypes.FETCH_ORGANIZATION_SUCCESS:
            return {
                ...state,
                isLoading: false,
                data: action.payload.organizations,
                error: null,
            };
        case actionTypes.FETCH_ORGANIZATION_FAILURE:
            return {
                ...state,
                isLoading: false,
                data: [],
                error: action.payload.error,
            };
        case actionTypes.SAVE_ORGANIZATION_REQUEST:
            return commontype();
        case actionTypes.SAVE_ORGANIZATION_SUCCESS:
            return {
                ...state,
                isSavingLoading: false,
                isSavingDataSuccess: true,
                isSavingDataError: null,
            };
        case actionTypes.SAVE_ORGANIZATION_FAILURE:
            return {
                ...state,
                isSavingLoading: false,
                isSavingDataSuccess: false,
                isSavingDataError: action.payload.error,
            };
        case actionTypes.UPDATE_ORGANIZATION_REQUEST:
            return commontype();
        case actionTypes.DELETE_ORGANIZATION_REQUEST:
            return commontype();
        case actionTypes.SET_SELECTED_ORGANIZATION: {
            const organization = state.data.find((c: OrganizationModel) => c.org_id === action.payload.id);
            if (organization) {
                return saveSelectedOrg(organization);
            } else if (state.organization) {
                return saveSelectedOrg(state.organization);
            } else {
                return {
                    ...state,
                };
            }
        }
        case actionTypes.REMOVE_SELECTED_ORGANIZATION:
            return {
                ...state,
                selectedOrganization: undefined,
            };
        case actionTypes.ALL_USER_REQUEST_SUCCESS:
            return {
                ...state,
                organization: action.payload.org_details,
            };
        case actionTypes.FETCH_ORG_BY_NAME:
            return fetchOrg();
        case actionTypes.FETCH_ORGANIZATION_BY_ID_REQUEST_SUCCESS:
            return saveSelectedOrg(action.payload.org);
        case actionTypes.REMOVE_ALL_ORGANIZATIONS:
            return { ...initialState };
        case actionTypes.FETCH_CUSTOMERS_BY_ID:
            return setCustomersLoading();
        case actionTypes.FETCH_CUSTOMERS_BY_ID_SUCCESS:
            return {
                ...state,
                isCustomersLoading: false,
                customers: action.payload,
                error: null,
            };
        case actionTypes.FETCH_CUSTOMERS_FAILURE:
            return {
                ...state,
                isCustomersLoading: false,
                customers: [],
                error: action.payload.error,
            };
        case actionTypes.DELETE_CUSTOMER_REQUEST:
            return setCustomersLoading();
        case actionTypes.DELETE_CUSTOMER_REQUEST_SUCCESS:
            return {
                ...state,
                isCustomersLoading: false,
                error: null,
            };
        case actionTypes.DELETE_CUSTOMER_REQUEST_FAILURE:
            return {
                ...state,
                isCustomersLoading: false,
                error: action.payload.error,
            };
        default:
            return {
                ...state,
            };
    }
};

import store from 'redux/store/configStore';
import { DeviceQuery, GraphResponse, ThingData } from 'shared/model/DeviceModel';
import { TimeEnums } from 'shared/utils/Constants';
import * as actionTypes from '../actionTypes';
import {
    FailurePayload,
    DeviceRequest,
    DeviceRequestSuccess,
    DeviceResponse,
    DeviceRequestFailure,
    DeviceRequestAppend,
    DeviceRequestAppendSuccess,
    SingleDeviceRequest,
    SingleDeviceRequestSuccess,
    SingleDeviceRequestFailure,
    SingleDeviceGraphRequest,
    SingleDeviceGraphRequestFailure,
    SingleDeviceGraphRequestSuccess,
    SingleDeviceCustomRangeRequest,
    SingleDeviceCustomRangeRequestSuccess,
    SingleDeviceCustomRangeRequestFailure,
} from '../types';

export const fetchDeviceRequest = (rest: DeviceQuery, save_last_query = true): DeviceRequest => ({
    type: actionTypes.DEVICE_REQUEST,
    payload: { ...rest, save_last_query, alert: store.getState().deviceFilterReducer.selectedAlert },
});

export const fetchDeviceRequestAndAppned = (payload: DeviceQuery): DeviceRequestAppend => ({
    type: actionTypes.DEVICE_REQUEST_APPEND,
    payload,
});

export const fetchDeviceSuccess = (payload: DeviceResponse): DeviceRequestSuccess => ({
    type: actionTypes.DEVICE_REQUEST_SUCCESS,
    payload,
});

export const fetchDeviceAppendSuccess = (payload: DeviceResponse): DeviceRequestAppendSuccess => ({
    type: actionTypes.DEVICE_REQUEST_APPEND_SUCCESS,
    payload,
});

export const fetchDeviceFailure = (payload: FailurePayload): DeviceRequestFailure => ({
    type: actionTypes.DEVICE_REQUEST_FAILURE,
    payload,
});

export const fetchSingleDevice = (payload: { id: string }): SingleDeviceRequest => ({
    type: actionTypes.SINGLE_DEVICE_REQUEST,
    payload,
});

export const fetchSingleDeviceSuccess = (payload: { thing: ThingData }): SingleDeviceRequestSuccess => ({
    type: actionTypes.SINGLE_DEVICE_REQUEST_SUCCESS,
    payload,
});

export const fetchSingleDeviceFailure = (payload: FailurePayload): SingleDeviceRequestFailure => ({
    type: actionTypes.SINGLE_DEVICE_REQUEST_FAILURE,
    payload,
});

export const fetchSingleDeviceGraph = (payload: {
    id: string;
    timestamp: TimeEnums | string;
}): SingleDeviceGraphRequest => ({
    type: actionTypes.SINGLE_DEVICE_GRAPH_REQUEST,
    payload,
});

export const fetchSingleDeviceGraphSuccess = (payload: {
    graphResponse: GraphResponse;
}): SingleDeviceGraphRequestSuccess => ({
    type: actionTypes.SINGLE_DEVICE_GRAPH_REQUEST_SUCCESS,
    payload,
});

export const fetchSingleDeviceGraphFailure = (payload: FailurePayload): SingleDeviceGraphRequestFailure => ({
    type: actionTypes.SINGLE_DEVICE_GRAPH_REQUEST_FAILURE,
    payload,
});

export const getAllDevice = (orgId?: number) => ({
    type: actionTypes.GET_ALL_DEVICES,
    payload: { orgId },
});

export const getAllDeviceSucess = (payload: DeviceResponse) => ({
    type: actionTypes.GET_ALL_DEVICES_SUCESS,
    payload,
});

export const getAllDeviceFailure = (payload: FailurePayload) => ({
    type: actionTypes.GET_ALL_DEVICES_FAILURE,
    payload,
});
export const fetchSingleDeviceCustomRange = (payload: {
    id: string;
    startTime: string;
    endTime: string;
}): SingleDeviceCustomRangeRequest => ({
    type: actionTypes.SINGLE_DEVICE_CUSTOM_RANGE_REQUEST,
    payload,
});

export const fetchSingleDeviceCustomRangeSuccess = (payload: {
    graphResponse: GraphResponse;
}): SingleDeviceCustomRangeRequestSuccess => ({
    type: actionTypes.SINGLE_DEVICE_CUSTOM_RANGE_SUCCESS,
    payload,
});

export const fetchSingleDeviceCustomRangeFailure = (
    payload: FailurePayload,
): SingleDeviceCustomRangeRequestFailure => ({
    type: actionTypes.SINGLE_DEVICE_CUSTOM_RANGE_FAILURE,
    payload,
});

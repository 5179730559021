export const DASHBOARD = '/dashboard';
export const CUSTOMERS = '/customers';
export const DEVICES = '/devices';
export const SETTINGS = '/settings';
export const MYORG = '/myorg';
export const PROFILE = '/profile';
export const HELP = '/help';
export const SITE = '/site';
export const LOGIN = '/login';
export const PAGE_404 = '/404';
export const PAGE_500 = '/500';
export const REGISTER = '/register';
export const FORGOT_PASSWORD = '/forgot-password';
export const RESET_PASSWORD = '/reset-password';
export const ADD_ORG = '/add-organization';
export const DEVICE_SITE = '/device-site';
export const CUSTOMER_SITE = '/customer-site';
export const DEVICE_COMMAND = '/device-command';
export const CUSTOMER_COMMAND = '/customer-command';
export const ADD_CUSTOMER = CUSTOMERS + ADD_ORG;
export const HOME = '/';
export const INVITED_USER = '/invited-user';
export const STYLEGUIDE = '/styleguide';
export const SINGLEDEVICE = '/single-device';
export const SINGLEDEVICEROUTE = DEVICES + SINGLEDEVICE;
export const MANAGE_SITE = SITE;
export const MANAGE_SITE_FROM_DEVICE = DEVICES + DEVICE_SITE;
export const MANAGE_COMMANDS_FROM_DEVICE = DEVICES + DEVICE_SITE + DEVICE_COMMAND;
export const MANAGE_SITE_FROM_CUSTOMERS = CUSTOMERS + CUSTOMER_SITE;
export const MANAGE_COMMANDS_FROM_CUSTOMERS = CUSTOMERS + CUSTOMER_SITE + CUSTOMER_COMMAND;
export const SERVICER = '/servicers';
export const PERFORMANCE = '/performance';
export const BAR = '/bar';
export const DASHBOARD_PERFORMANCE = DASHBOARD + PERFORMANCE;
export const DASHBOARD_BAR_PERFORMANCE = DASHBOARD + PERFORMANCE + BAR;
export const RANGE = '/range';
export const DASHBOARD_PERFORMANCE_RANGE = DASHBOARD_PERFORMANCE + RANGE;
export const FIRMWARES = '/firmwares';
export const GET_ALL_DEVICE = 'get-all-device';
export const USERS = '/user';
export const MANAGE_USERS = CUSTOMERS + USERS;
export const CUSTOM_RANGE = '/custom-range';

import {
    CUSTOM_RANGE,
    DASHBOARD,
    DASHBOARD_BAR_PERFORMANCE,
    DASHBOARD_PERFORMANCE,
    DASHBOARD_PERFORMANCE_RANGE,
} from 'containers/urlConstant';
import { http_get } from './BaseURLAxios';
import { Performance_Benefits } from 'shared/utils/Constants';
import { IDASHBOARDGRAPH } from 'redux/types';

export const getDashboardData = async () => {
    return await http_get(DASHBOARD).then((res: any) => {
        return res;
    });
};

export const getDashboardPerformaceGraphData = async (type: string, timestamp: string) => {
    return await http_get(DASHBOARD_PERFORMANCE + '/' + type + '/' + timestamp).then((res: any) => {
        return res;
    });
};

export const getDashboardPerformaceBarData = async () => {
    return await http_get(DASHBOARD_BAR_PERFORMANCE).then((res: any) => {
        return res;
    });
};

export const getRanges = async () => {
    return await http_get(DASHBOARD_PERFORMANCE_RANGE).then((res: any) => {
        return res;
    });
};
export const getDashboardCustomRangeData = async (
    type: Performance_Benefits,
    startTime: string,
    endTime: string,
): Promise<IDASHBOARDGRAPH> => {
    return await http_get(
        `${DASHBOARD_PERFORMANCE}/${type}${CUSTOM_RANGE}?start_time=${startTime}&end_time=${endTime}`,
    ).then((res: any) => {
        return res;
    });
};

import { all, call, Effect, put, takeLatest } from 'redux-saga/effects';
import {
    fetchDashboardCustomRangeFailure,
    fetchDashboardCustomRangeSuccess,
    fetchDashboardFailure,
    fetchDashboardFromCarddFailure,
    fetchDashboardFromCardSuccess,
    fetchDashboardPerformanceBarFailure,
    fetchDashboardPerformanceBarSuccess,
    fetchDashboardPerformanceGraphFailure,
    fetchDashboardPerformanceGraphSuccess,
    fetchDashboardSuccess,
} from '../actions';
import * as actionTypes from '../actionTypes';
import {
    getDashboardCustomRangeData,
    getDashboardData,
    getDashboardPerformaceBarData,
    getDashboardPerformaceGraphData,
} from '../../api/dashboardApi';
import { IBARSUCCESSRESPONSE, IDASHBOARD, IDASHBOARDGRAPH } from 'redux/types';

/*
  Worker Saga: Fired on FETCH_DASHBOARD_REQUEST action
*/
function* fetchDashboardSaga() {
    try {
        const response: IDASHBOARD = yield call(getDashboardData);
        yield put(
            fetchDashboardSuccess({
                dashboard: response,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchDashboardFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on FETCH_DASHBOARD_REQUEST_FROM_CARD action from performance benefits card
*/
function* fetchDashboardSagaFromCard() {
    try {
        const response: IDASHBOARD = yield call(getDashboardData);
        yield put(
            fetchDashboardFromCardSuccess({
                dashboard: response,
            }),
        );
    } catch (e: any) {
        yield put(
            fetchDashboardFromCarddFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on FETCH_DASHBOARD_PERFORMANCE_GRAPH_REQUEST action
*/
function* fetchDashboardPerformaceGraphSaga(action: Effect) {
    try {
        const response: IDASHBOARDGRAPH = yield call(
            getDashboardPerformaceGraphData,
            action.payload.type,
            action.payload.timestamp,
        );
        yield put(fetchDashboardPerformanceGraphSuccess(response));
    } catch (e: any) {
        yield put(
            fetchDashboardPerformanceGraphFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Worker Saga: Fired on FETCH_DASHBOARD_PERFORMANCE_BAR_REQUEST action
*/
function* fetchDashboardPerformaceBarSaga() {
    try {
        const response: IBARSUCCESSRESPONSE = yield call(getDashboardPerformaceBarData);
        yield put(fetchDashboardPerformanceBarSuccess(response));
    } catch (e: any) {
        yield put(
            fetchDashboardPerformanceBarFailure({
                error: e.message,
            }),
        );
    }
}

function* fetchDashboardCustomRangeSaga(action: Effect) {
    try {
        const response: IDASHBOARDGRAPH = yield call(
            getDashboardCustomRangeData,
            action.payload.type,
            action.payload.startTime,
            action.payload.endTime,
        );
        yield put(fetchDashboardCustomRangeSuccess(response));
    } catch (e: any) {
        yield put(
            fetchDashboardCustomRangeFailure({
                error: e.message,
            }),
        );
    }
}

/*
  Starts worker saga on latest dispatched `FETCH_DASHBOARD_REQUEST` action.
*/
function* dashboardSaga() {
    yield all([takeLatest(actionTypes.FETCH_DASHBOARD_REQUEST, fetchDashboardSaga)]);
    yield all([takeLatest(actionTypes.FETCH_DASHBOARD_PERFORMANCE_GRAPH_REQUEST, fetchDashboardPerformaceGraphSaga)]);
    yield all([takeLatest(actionTypes.FETCH_DASHBOARD_PERFORMANCE_BAR_REQUEST, fetchDashboardPerformaceBarSaga)]);
    yield all([takeLatest(actionTypes.FETCH_DASHBOARD_REQUEST_FROM_CARD, fetchDashboardSagaFromCard)]);
    yield all([takeLatest(actionTypes.FETCH_DASHBOARD_CUSTOM_RANGE_REQUEST, fetchDashboardCustomRangeSaga)]);
}

export default dashboardSaga;

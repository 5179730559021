import { AllocateDevices, InstallDevices, UnAllocateDevices, UnInstallDevices } from 'shared/model/SiteModel';
import { http_delete, http_get, http_post, http_put } from './BaseURLAxios';
import {
    ALLOCATE,
    DEVICES,
    HEIRARCHY,
    INSTALL,
    NON_OU,
    OU,
    SIE_RHS,
    SIE_RHS_GRAPH,
    SITE,
    UNALLOCATE,
    UNINSTALL,
} from './urlConstants';
import { CUSTOM_RANGE } from 'containers/urlConstant';

export const getSiteHeirarchyAPI = async (id: number) => {
    return await http_get(SITE + '/' + HEIRARCHY + '/' + id).then((res: any) => {
        return res;
    });
};

export const addSiteAPI = async (body: any) => {
    return await http_post(SITE, body).then((res: any) => {
        return res;
    });
};

export const removeSiteAPI = async (id: any, org_id: any) => {
    return await http_delete(SITE + '/' + id + '/' + org_id).then((res: any) => {
        return res;
    });
};

export const updateSiteApi = async (id: any, body: any) => {
    return await http_put(SITE + '/' + id, body).then((res: any) => {
        return res;
    });
};

export const getSiteRhsApi = async (body: { device_list: string[] }) => {
    return await http_post(SIE_RHS, body).then((res: any) => {
        return res;
    });
};

export const getSiteGraphData = async (device_list: { device_list: string[] }, timestamp: string) => {
    return await http_post(SIE_RHS_GRAPH + '/' + timestamp, device_list).then((res: any) => {
        return res;
    });
};
export const getAllocateDevicesAPI = async (oid: number, id: number) => {
    return await http_get(SITE + '/' + OU + '/' + DEVICES + '/' + oid + '/' + id).then((res: any) => {
        return res;
    });
};
export const allocateDeviceAPI = async (body: AllocateDevices) => {
    return await http_post(SITE + '/' + ALLOCATE, body).then((res: any) => {
        return res;
    });
};
export const unAllocateDeviceAPI = async (body: UnAllocateDevices) => {
    return await http_post(SITE + '/' + UNALLOCATE, body).then((res: any) => {
        return res;
    });
};
export const getInstallDevicesAPI = async (oid: number, id: number) => {
    return await http_get(SITE + '/' + NON_OU + '/' + DEVICES + '/' + oid + '/' + id).then((res: any) => {
        return res;
    });
};
export const installDeviceAPI = async (body: InstallDevices) => {
    return await http_post(SITE + '/' + INSTALL, body).then((res: any) => {
        return res;
    });
};
export const unInstallDeviceAPI = async (body: UnInstallDevices) => {
    return await http_post(SITE + '/' + UNINSTALL, body).then((res: any) => {
        return res;
    });
};
export const getSiteGraphDataCustomRange = async (device_list: string[], startTime: string, endTime: string) => {
    const body = {
        device_list: device_list,
        start_time: startTime,
        end_time: endTime,
    };
    return await http_post(SIE_RHS_GRAPH + CUSTOM_RANGE, body).then((res: any) => {
        return res;
    });
};

import {
    AddDeviceSerialNo,
    ChangeButtonState,
    ChangeDeviceMode,
    ChangeIsLoadingState,
    DeviceConfirmationFailureInterface,
    FailurePayload,
    AddRowValue,
    ChangeDeviceConfirmationSuccessInterface,
    ChangeDeviceModeFailureInterface,
    ChangeDeviceModeSuccessInterface,
    Deviceconfirm,
    AddDivValue,
    CommandData,
    FetchCommandsTableData,
    FetchCommandsTableDataSuccessInterface,
    FetchCommandsTableDataFailureInterface,
    CommandsSuccessInterface,
    SetDeviceID,
    DeviceList,
    SendScheduleData,
    AddSchedules,
    ManualSelfCleaningCommandData,
    SetSelecteSearched,
    RemoveSelectedSearched,
    RemoveAllSearched,
    SetToggleValue,
} from 'redux/types/deviceCommandModel';
import * as actionTypes from '../actionTypes';
import { FirmwareVersionDetailsCommandData } from 'redux/types/firmwareActionModel';

export const addDeviceSeriralNo = (data: string[]): AddDeviceSerialNo => ({
    type: actionTypes.ADD_DEVICE_SERIAL_NO,
    payload: { data },
});
export const clearcheckbox = (data: boolean): any => ({
    type: actionTypes.CLEAR_CHECKBOX,
    payload: { data },
});
export const adddivvalue = (data: string[]): AddDivValue => ({
    type: actionTypes.ADD_DIV_VALUE,
    payload: { data },
});
export const addrowvalue = (data: string[]): AddRowValue => ({
    type: actionTypes.ADD_ROW_VALUE,
    payload: { data },
});
export const changeDeviceMode = (
    device_list: DeviceList[],
    command: string,
    org_id: number,
    command_data?:
        | CommandData
        | SendScheduleData
        | FirmwareVersionDetailsCommandData
        | Array<number | string>
        | ManualSelfCleaningCommandData,
    action?: string,
): ChangeDeviceMode => ({
    type: actionTypes.CHANGE_DEVICE_MODE,
    payload: {
        device_list: device_list,
        command: command,
        command_data: command_data,
        org_id: org_id,
        action: action,
    },
});
export const changeDeviceModeSuccess = (data: any): ChangeDeviceModeSuccessInterface => ({
    type: actionTypes.CHANGE_DEVICE_MODE_SUCCESS,
    payload: { data },
});

export const changeDeviceModeFailure = (data: FailurePayload): ChangeDeviceModeFailureInterface => ({
    type: actionTypes.CHANGE_DEVICE_MODE_FAILURE,
    payload: { data },
});

export const changeDeviceConfirmationSuccess = (data: Deviceconfirm): ChangeDeviceConfirmationSuccessInterface => ({
    type: actionTypes.CHANGE_DEVICE_MODE_CONFIRMATION_SUCCESS,
    payload: { data },
});
export const ChangeDeviceConfirmationSuccessFailure = (data: FailurePayload): any => ({
    type: actionTypes.CHANGE_DEVICE_MODE_CONFIRMATION_FAILURE,
    payload: { data },
});
export const DeviceConfirmationFailure = (data: FailurePayload): DeviceConfirmationFailureInterface => ({
    type: actionTypes.DEVICE_MODE_FAILURE,
    payload: { data },
});
export const changeIsLoadingState = (): ChangeIsLoadingState => ({
    type: actionTypes.CHANGE_ISLOADING_STATE,
});

export const clearIsLoadingState = (): any => ({
    type: actionTypes.CLEAR_ISLOADING_STATE,
});
export const changeButtonState = (data: boolean): ChangeButtonState => ({
    type: actionTypes.CHANGE_BUTTON_STATE,
    payload: { data },
});
export const addSchedule = (data: boolean): AddSchedules => ({
    type: actionTypes.ADD_SCHEDULE,
    payload: { data },
});
export const fetchCommandsTableData = (data: string | number): FetchCommandsTableData => ({
    type: actionTypes.FETCH_REMOTE_COMMANDS_TABLE_DATA,
    payload: { data },
});

export const fetchCommandsTableDataSuccess = (
    data: CommandsSuccessInterface,
): FetchCommandsTableDataSuccessInterface => ({
    type: actionTypes.FETCH_REMOTE_COMMANDS_TABLE_DATA_SUCCESS,
    payload: { data },
});

export const fetchCommandsTableDataFailure = (data: FailurePayload): FetchCommandsTableDataFailureInterface => ({
    type: actionTypes.FETCH_REMOTE_COMMANDS_TABLE_DATA_FAILURE,
    payload: { data },
});
export const setDeviceId = (data: number): SetDeviceID => ({
    type: actionTypes.SET_DEVICE_ID,
    payload: { data },
});
export const setSelectedSearched = (value: string[]): SetSelecteSearched => ({
    type: actionTypes.SET_SELECTED_SEARCHED,
    payload: value,
});

export const removeSelectedSearched = (value: string): RemoveSelectedSearched => ({
    type: actionTypes.REMOVE_SELECTED_SEARCHED,
    payload: value,
});
export const removeAllSearched = (): RemoveAllSearched => ({
    type: actionTypes.REMOVE_ALL_SEARCHED,
});
export const setToggleValue = (value: string): SetToggleValue => ({
    type: actionTypes.SET_TOGGLE_VALUE,
    payload: value,
});

import { FirmWares, FirmwareActions, Model } from 'redux/types/firmwareActionModel';
import * as actionTypes from '../actionTypes';

const initialState: FirmWares = {
    modelName: [''],
    isLoading: false,
    isFirmwareUpdateSuccess: false,
    error: null,
    firmwaresList: [],
    page: 1,
    rowsPerPage: 10,
    updatedfirmwaresList: [],
    viewFirmwareOpened: false,
    addFirmwareOpened: false,
    editFirmwareOpened: false,
    description: undefined,
    firmwareBinaryFile: undefined,
    otherFile: undefined,
    isFirmwareListLoading: false,
    latestVersion: [],
    models: [],
};

export const firmwaresReducer = (state = initialState, action: FirmwareActions): FirmWares => {
    switch (action.type) {
        case actionTypes.FETCH_MODEL_NAME_SUCCESS:
            if (Array.isArray(action?.payload)) {
                return {
                    ...state,
                    modelName: action?.payload?.map((item: Model) => item?.model_name),
                    models: action.payload,
                };
            }
            return state;
        case actionTypes.ADD_FIRMWARE_REQUEST:
        case actionTypes.UPDATE_FIRMWARE_REQUEST:
        case actionTypes.DELETE_FIRMWARE_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case actionTypes.ADD_FIRMWARE_SUCCESS:
        case actionTypes.UPDATE_FIRMWARE_SUCCESS:
        case actionTypes.DELETE_FIRMWARE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isFirmwareUpdateSuccess: true,
            };

        case actionTypes.ADD_FIRMWARE_FAILURE:
        case actionTypes.UPDATE_FIRMWARE_FAILURE:
        case actionTypes.DELETE_FIRMWARE_FAILURE:
            return {
                ...state,
                isLoading: false,
                isFirmwareUpdateSuccess: false,
                error: action.payload.error,
            };
        case actionTypes.SET_ADD_FIRMWARE_SUCCESS:
            return {
                ...state,
                isFirmwareUpdateSuccess: action?.payload,
            };
        case actionTypes.FETCH_FIRMWARE_LIST:
            return {
                ...state,
                isFirmwareListLoading: true,
            };
        case actionTypes.FETCH_FIRMWARE_LIST_SUCCESS:
            return {
                ...state,
                firmwaresList: action?.payload,
                isFirmwareListLoading: false,
            };
        case actionTypes.FETCH_FIRMWARE_LATEST_VERSION_SUCCESS:
            return {
                ...state,
                latestVersion: action?.payload,
            };
        case actionTypes.CHANGE_PAGE:
            return { ...state, page: action.payload };
        case actionTypes.CHANGE_ROWS_PER_PAGE:
            return { ...state, rowsPerPage: action.payload };
        case actionTypes.UPDATE_FIRMWARE_LIST:
            return { ...state, updatedfirmwaresList: action.payload };
        case actionTypes.SET_VIEW_FIRMWARE_OPEN:
            return { ...state, viewFirmwareOpened: action.payload };
        case actionTypes.SET_ADD_FIRMWARE_OPEN:
            return { ...state, addFirmwareOpened: action.payload };
        case actionTypes.SET_EDIT_FIRMWARE_OPEN:
            return { ...state, editFirmwareOpened: action.payload };
        case actionTypes.FETCH_SPECIFIC_FIRMWARE_DATA_SUCCESS:
            return {
                ...state,
                description: action?.payload?.description,
                firmwareBinaryFile: action?.payload?.firmware_binary_file,
                otherFile: action?.payload?.other_files,
            };
        case actionTypes.CLEAR_FIRMWARE_DATA:
            return { ...state, description: undefined, firmwareBinaryFile: undefined, otherFile: undefined };
        default:
            return {
                ...state,
            };
    }
};

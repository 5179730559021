import {
    deleteUserAPI,
    editUserAPI,
    getAllUsersAPI,
    getUserAPI,
    getUserRoles,
    inviteNewUserAPI,
    resendInviteAPI,
    swithTemperaturePreference,
    swithUserTheme,
    updateUserAPI,
} from 'api/UserAPI';
import { t } from 'i18next';
import { all, call, Effect, put, takeLatest } from 'redux-saga/effects';
import {
    fetchFilterRequest,
    fetchOrganizationRequest,
    fetchRangesRequest,
    getAllUsersAction,
    getAllUsersFaiureActionn,
    getAllUsersSuccessAction,
    getUserRolesFailureAction,
    getUserRolessuccessAction,
    inviteUserFailureAction,
    inviteUserSuccessAction,
    setToastAction,
    userRequest,
    userRequestFailure,
    userRequestSuccess,
    userUpdateRequestFailureAction,
    userUpdateRequestSuccessAction,
} from 'redux/actions';
import store from 'redux/store/configStore';
import { OrganizationModel, RolesI, UserModel, UsersModel } from 'shared/model';
import { ToastVariant, USER_ROLE_ID } from 'shared/utils/Constants';
import * as actionTypes from '../actionTypes';
import { SWITCH_TEMPERATURE_SUCCESS_FAILURE, SWITCH_THEME_SUCCESS_FAILURE } from '../actionTypes';
import { logoutService } from 'shared/service/CommonService';

/*
  Worker Saga: Fired on USER_REQUEST action
*/
function* fetchUserSaga() {
    try {
        const response: UserModel = yield call(getUserAPI);
        if (response?.roleID != USER_ROLE_ID.FacilityManager) {
            yield put(userRequestSuccess(response));
            yield put(fetchRangesRequest());
            yield put(fetchFilterRequest());
        } else {
            logoutService();
            store.dispatch(setToastAction(ToastVariant.DANGER, t('facility_manager_login__error_message')));
        }
    } catch (e: any) {
        yield put(
            userRequestFailure({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Worker Saga: Fired on USER_UPDATE_REQUEST action
*/
function* userUpdateSaga(action: Effect) {
    try {
        const res: string = yield call(updateUserAPI, action.payload);
        if (action.payload?.new_pwd) {
            yield put(setToastAction(ToastVariant.SUCCESS, t('password_changed_success')));
        } else {
            yield put(setToastAction(ToastVariant.SUCCESS, res));
        }
        yield put(userUpdateRequestSuccessAction());
        yield put(userRequest());
    } catch (e: any) {
        yield put(
            userUpdateRequestFailureAction({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Worker Saga: Fired on ALL_USER_REQUEST action
*/
function* allUserSaga() {
    try {
        const response: {
            org_details: OrganizationModel;
            users: UsersModel[];
        } = yield call(getAllUsersAPI);
        yield put(getAllUsersSuccessAction(response));
    } catch (e: any) {
        yield put(
            getAllUsersFaiureActionn({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Worker Saga: Fired on INVITE_USER_REQUEST action
*/
function* inviteUserSaga(action: Effect) {
    try {
        yield call(inviteNewUserAPI, action.payload);
        store.dispatch(setToastAction(ToastVariant.SUCCESS, t('invite_user_success') + action.payload?.email));
        yield put(inviteUserSuccessAction());
        yield put(getAllUsersAction());
    } catch (e: any) {
        yield put(
            inviteUserFailureAction({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Worker Saga: Fired on EDIT_USER_REQUEST action
*/
function* editUserSaga(action: Effect) {
    try {
        const response: string = yield call(editUserAPI, action.payload);
        store.dispatch(setToastAction(ToastVariant.SUCCESS, response));
        yield put(inviteUserSuccessAction());
        yield put(getAllUsersAction());
        yield put(fetchOrganizationRequest());
    } catch (e: any) {
        yield put(
            inviteUserFailureAction({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Worker Saga: Fired on RESEND_INVITE_REQUEST action
*/
function* resendInviteSaga(action: Effect) {
    try {
        yield call(resendInviteAPI, action.payload);
        store.dispatch(setToastAction(ToastVariant.SUCCESS, t('resend_invite_success')));
        yield put(inviteUserSuccessAction());
        yield put(getAllUsersAction());
    } catch (e: any) {
        yield put(
            inviteUserFailureAction({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Worker Saga: Fired on DELETE_USER_REQUEST action
*/
function* deleteUserSaga(action: any) {
    try {
        yield call(deleteUserAPI, action.id);
        store.dispatch(setToastAction(ToastVariant.SUCCESS, t('user_deleted_success')));
        yield put(inviteUserSuccessAction());
        yield put(getAllUsersAction());
    } catch (e: any) {
        yield put(
            inviteUserFailureAction({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Worker Saga: Fired on GET_USER_ROLES action
*/
function* userRolesSaga(action: any) {
    try {
        const roles: RolesI[] = yield call(getUserRoles, action.payload.org_type);
        yield put(getUserRolessuccessAction(roles));
    } catch (e: any) {
        yield put(
            getUserRolesFailureAction({
                error: e?.response?.data,
            }),
        );
    }
}

/*
  Worker Saga: Fired on SWITCH_THEME action
*/
function* switchUserTheme(action: any) {
    try {
        const res: string = yield call(swithUserTheme, action.payload.theme);
        store.dispatch(setToastAction(ToastVariant.SUCCESS, res));
        yield call(fetchUserSaga);
    } catch (e: any) {
        store.dispatch(setToastAction(ToastVariant.DANGER, e?.response?.data));
    } finally {
        yield put({ type: SWITCH_THEME_SUCCESS_FAILURE });
    }
}

/*
  Worker Saga: Fired on SWITCH_TEMPERATURE action
*/
function* switchTemperaturePrefrenceSaga(action: any) {
    try {
        const res: string = yield call(swithTemperaturePreference, action.payload.temperature);
        store.dispatch(setToastAction(ToastVariant.SUCCESS, res));
        yield call(fetchUserSaga);
    } catch (e: any) {
        store.dispatch(setToastAction(ToastVariant.DANGER, e?.response?.data));
    } finally {
        yield put({ type: SWITCH_TEMPERATURE_SUCCESS_FAILURE });
    }
}

/*
  Starts worker saga on latest dispatched `USER_REQUEST`, `USER_UPDATE_REQUEST` action.
*/
function* userSaga() {
    yield all([
        takeLatest(actionTypes.USER_REQUEST, fetchUserSaga),
        takeLatest(actionTypes.USER_UPDATE_REQUEST, userUpdateSaga),
        takeLatest(actionTypes.ALL_USER_REQUEST, allUserSaga),
        takeLatest(actionTypes.INVITE_USER_REQUEST, inviteUserSaga),
        takeLatest(actionTypes.EDIT_USER_REQUEST, editUserSaga),
        takeLatest(actionTypes.RESEND_INVITE_REQUEST, resendInviteSaga),
        takeLatest(actionTypes.DELETE_USER_REQUEST, deleteUserSaga),
        takeLatest(actionTypes.GET_USER_ROLES, userRolesSaga),
        takeLatest(actionTypes.SWITCH_THEME, switchUserTheme),
        takeLatest(actionTypes.SWITCH_TEMPERATURE, switchTemperaturePrefrenceSaga),
    ]);
}

export default userSaga;

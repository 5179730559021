import { AppState } from 'redux/reducers';
import { createSelector } from 'reselect';

const getDeviceData = (state: AppState) => state?.DeviceCommandReducer?.deviceData;
const getParticularDeviceData = (state: AppState) => state?.DeviceCommandReducer?.particularDeviceData;
const getParticularDeviceName = (state: AppState) => state?.DeviceCommandReducer?.FloorName;
const getChildSiteNames = (state: AppState) => state?.DeviceCommandReducer?.childSiteNames;
const getDeviceSerialNo = (state: AppState) => state?.DeviceCommandReducer?.devicesrno;
export const getDeviceModedata = (state: AppState) => state?.DeviceCommandReducer?.devicemodedata;
export const getDeviceConfirmationData = (state: AppState) => state?.DeviceCommandReducer?.deviceconfirmationdata;
const getDeviceModedataLoading = (state: AppState) => state?.DeviceCommandReducer?.isLoading;
const getDevicedataResponseLoading = (state: AppState) => state?.DeviceCommandReducer?.isResponseSuccess;
const getShowButtonPanel = (state: AppState) => state?.DeviceCommandReducer?.showButonPanel;
const getError = (state: AppState) => state?.DeviceCommandReducer?.isError;
const getErrorMessage = (state: AppState) => state?.DeviceCommandReducer?.errorMessage;
const getButtonState = (state: AppState) => state?.DeviceCommandReducer?.isButton;
const getButton = (state: AppState) => state?.DeviceCommandReducer?.isButtonClicked;
const getCheckboxState = (state: AppState) => state?.DeviceCommandReducer?.isCheckbox;
const getCheckboxDiv = (state: AppState) => state?.DeviceCommandReducer?.checkboxDiv;
const getCheckboxRow = (state: AppState) => state?.DeviceCommandReducer?.checkboxRow;
const getTableLoading = (state: AppState) => state?.DeviceCommandReducer?.tableLoading;
const getDeviceCount = (state: AppState) => state?.DeviceCommandReducer?.deviceCount;
const getSearchedValue = (state: AppState) => state?.DeviceCommandReducer?.searchChips;
const getIsTimeZoneInConsistentValue = (state: AppState) => state?.DeviceCommandReducer?.isTimeZoneInconsistent;
const getTimeZone = (state: AppState) => state?.DeviceCommandReducer?.timeZone;
const getToggleValueFromStore = (state: AppState) => state?.DeviceCommandReducer?.toggleValue;

export const getdevicedata = createSelector(getDeviceData, (deviceData) => deviceData);
export const getchildsitenames = createSelector(getChildSiteNames, (childSiteNames) => childSiteNames);
export const getdevicedataname = createSelector(getParticularDeviceName, (FloorName) => FloorName);
export const getdeviceserialno = createSelector(getDeviceSerialNo, (devicesrno) => devicesrno);
export const getdevicemodedata = createSelector(getDeviceModedata, (devicemodedata) => devicemodedata);
export const getshowbuttonpanel = createSelector(getShowButtonPanel, (showButonPanel) => showButonPanel);
export const geterror = createSelector(getError, (isError) => isError);
export const geterrormessage = createSelector(getErrorMessage, (errorMessage) => errorMessage);
export const getbuttonstate = createSelector(getButtonState, (isButton) => isButton);
export const getbutton = createSelector(getButton, (isButtonClicked) => isButtonClicked);
export const getcheckbox = createSelector(getCheckboxState, (isCheckbox) => isCheckbox);
export const getcheckboxdiv = createSelector(getCheckboxDiv, (checkboxDiv) => checkboxDiv);
export const getcheckboxrow = createSelector(getCheckboxRow, (checkboxRow) => checkboxRow);
export const gettableloading = createSelector(getTableLoading, (tableLoading) => tableLoading);
export const getdeviceconfirmationdata = createSelector(
    getDeviceConfirmationData,
    (Deviceconfirmationdata) => Deviceconfirmationdata,
);
export const getdevicemodedataloading = createSelector(
    getDeviceModedataLoading,
    (devicemodedataloading) => devicemodedataloading,
);
export const getdevicedataresponseloading = createSelector(
    getDevicedataResponseLoading,
    (isResponseSuccess) => isResponseSuccess,
);
export const getparticulardevicedata = createSelector(
    getParticularDeviceData,
    (particularDeviceData) => particularDeviceData,
);
export const getdevicecount = createSelector(getDeviceCount, (deviceCount) => deviceCount);
export const getsearchvalue = createSelector(getSearchedValue, (searchChips) => searchChips);
export const getIsTimeZoneInConsistent = createSelector(
    getIsTimeZoneInConsistentValue,
    (isTimeZoneInconsistent) => isTimeZoneInconsistent,
);
export const getTimezone = createSelector(getTimeZone, (timeZone) => timeZone);
export const getToggleValue = createSelector(getToggleValueFromStore, (toggleValue) => toggleValue);

import { KeyToLeftMapping, SliderState } from 'redux/types/deviceCommandModel';
import { SliderThumbState } from 'shared/Components/slider/Slider';

export enum ToastVariant {
    SUCCESS = 'toaster-success',
    DANGER = 'toaster-danger',
    WARNING = 'toaster-warning',
    INFO = 'toaster-info',
}

export enum UserStatus {
    UNCONFIRMED = 'UNCONFIRMED',
    ACTIVATED = 'ACTIVATED',
    ARCHIVED = 'ARCHIVED',
    COMPROMISED = 'COMPROMISED',
    INVITED = 'INVITED',
    RESET_REQUIRED = 'RESET_REQUIRED',
    FORCE_CHANGE_PASSWORD = 'FORCE_CHANGE_PASSWORD',
}

export enum URL_Type {
    invitation = 'invitation',
    forgot_password = 'forgot_password',
}

export enum VIEW {
    LIST = 'LIST',
    CARD = 'CARD',
}

export const KeyWordsValue: any = {
    is_greater_than: { title: 'Is Greater Than', value: '>' },
    is_less_than: { title: 'Is Less Than', value: '<' },
    is_equal_to: { title: 'Is Equal To', value: ':' },
};

export const DEFAULT_QUERY = '*';
export const MIN_QUERY_RESULT = 50;

export const MAX_LENGTH = 50;
export const MOBILE_LENGTH = 20;
export const MAXED_LENGTH = 100;
export const MEDIUM_LENGTH = 10;
export const MIN_LENGTH = 3;

export enum StatusEnum {
    HAZARDOUS = 'Hazardous',
    MODERATE = 'Moderate',
    HEALTHY = 'Healthy',
    IDEAL = 'Ideal',
    EMPTY = 'empty',
    OFFLINE = 'offline',
    TOTAL_DEVICES = 'total_devices',
    AVAILABLE_DEVICES = 'available_devices',
    TEMP = 'temp',
    HUMIDITY = 'humidity',
    DATAERROR = 'DataError',
}

export enum StatusEnumColors {
    HAZARDOUS = '#F41148',
    MODERATE = '#726ADF',
    HEALTHY = '#55B1FD',
    IDEAL = '#36CB79',
    EMPTY = '#ffffff33',
    OFFLINE = '#817B80',
    TEMP = '#874CEE',
    HUMIDITY = '#d5e33b',
    DATAERROR = '#817b80',
}

export enum DEVICE_STATUS {
    OFFLINE = 'Offline',
    ONLINE = 'Online',
}

export enum Device_Fitler_URL {
    InputAqiCategory = 'input_aqi_category',
    Status = 'status',
    selectedTab = 'selected_tab',
    Alerts = 'alert',
}

export enum Query_Enums {
    CONNECTED = 'receiving_pollutant_data',
    ATTRIBUTES = 'attributes',
    INUTAQICATEGORY = 'input_aqi_category',
    INUTAQI = 'input_aqi',
    OUTPUTAQICATEGORY = 'output_aqi_category',
    OUTPUTAQI = 'output_aqi',
    LIFECYCLESTATE = 'lifecycle_state',
    LOCATION = 'location',
    ORGID = 'org_id',
    site_id = 'site_id',
    MODEL = 'model_name',
    THING_TYPE = 'thingTypeName',
}

export const INPUT = 'INPUT';
export const OUTPUT = 'OUTPUT';

export enum SiteLocationEnum {
    AtSiteInstalled = 'AtSiteInstalled',
    AtSite = 'AtSite',
    DeliveredToCustomer = 'DeliveredToCustomer',
}

export enum SiteLifecycleEnum {
    InUse = 'InUse',
    ReadyForUse = 'ReadyForUse',
}

export enum AlertType {
    RENEWALFAILURE = 'RENEWAL_FAILURE',
    HIGHTEMP = 'HIGHTEMP',
    HIGHVOC = 'HIGHVOC',
    FILTERHEALTH = 'FILTERHEALTH',
    OFFLINE = 'OFFLINE',
}

export enum OtherEnums {
    OFFLINE = 'OFFLINE',
    OUT_OF_RANGE_PM1 = 'OUT_OF_RANGE_PM1',
    OUT_OF_RANGE_PM10 = 'OUT_OF_RANGE_PM10',
    OUT_OF_RANGE_PM25 = 'OUT_OF_RANGE_PM25',
    OUT_OF_RANGE_CO2 = 'OUT_OF_RANGE_CO2',
    OUT_OF_RANGE_TVOC = 'OUT_OF_RANGE_TVOC',
    OUT_OF_RANGE_HUMIDITY = 'OUT_OF_RANGE_HUMIDITY',
    OUT_OF_RANGE_TEMPERATURE = 'OUT_OF_RANGE_TEMPERATURE',
    INSUFFICIENT_DATA_FOR_IAQ = 'INSUFFICIENT_DATA_FOR_IAQ',
    INSUFFICIENT_DATA_FOR_VIRUS_RISK = 'INSUFFICIENT_DATA_FOR_VIRUS_RISK',
    INSUFFICIENT_DATA_FOR_PRODUCTIVITY_RISK = 'INSUFFICIENT_DATA_FOR_PRODUCTIVITY_RISK',
}

export type DeviceAlertsEnum = AlertType | OtherEnums;

export enum DeviceTabs {
    INSTALLED = 'installed_device',
    OTHER = 'other_',
    TESTING = 'testing',
}

export const METALMARK_ORGID = '1111111111';

export enum TimeEnums {
    LAST_ONE_HOUR = 'LAST_ONE_HOUR',
    LAST_SIX_HOUR = 'LAST_SIX_HOUR',
    LAST_TWELVE_HOUR = 'LAST_TWELVE_HOUR',
    LAST_ONE_DAY = 'LAST_ONE_DAY',
    LAST_TWO_DAY = 'LAST_TWO_DAY',
    LAST_SEVEN_DAY = 'LAST_SEVEN_DAY',
    LAST_THIRTY_DAY = 'LAST_THIRTY_DAY',
    LAST_SIX_MONTH = 'LAST_SIX_MONTH',
    LAST_ONE_YEAR = 'LAST_ONE_YEAR',
}

export enum PollutantSymbolByName {
    PPM = 'ppm',
    MICRO_PER_METER = 'µg/m3',
    DEGREE_CELSIUS = '°C',
    DEGREE_FAHRENHEIT = '°F',
    PERCENTAGE = '%',
    COUNT_PER_CM3 = '#/cm3',
    PRESSURE = 'pa',
    FANSPEED = 'fs',
    TVOC = 'tvoc',
}

export const PollutantSymbolMapping: any = {
    ppm: 'PPM',
    'µg/m3': 'µg/m³',
    '°C': '°C',
    '°F': '°F',
    '%': '%',
    '#/cm3': '(#/cm³)',
    pa: 'Pa',
    fs: 'Fan Speed',
    tvoc: 'TVOC Index',
};

export const Pollutants: any = {
    PM_01: {
        title: 'PM1',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.MICRO_PER_METER],
        color: '#1785E0',
        mappedValue: 'avg_input_PM1',
        label: 'PM1',
        type: 'concentration',
    },
    PM_25: {
        title: 'PM25',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.MICRO_PER_METER],
        color: '#ee293d',
        mappedValue: 'avg_input_PM25',
        label: 'PM25',
        type: 'concentration',
    },
    //As this will need in future so commenting this code
    // PM_10: {
    //     title: 'PM10',
    //     symbol: PollutantSymbolMapping[PollutantSymbolByName.MICRO_PER_METER],
    //     color: '#87D0A0',
    //     mappedValue: 'avg_input_PM10',
    //     label: 'PM10',
    //     type: 'concentration',
    // },

    NPM_05: {
        title: 'PM05',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.COUNT_PER_CM3],
        color: '#FF6B35',
        mappedValue: 'avg_input_num_0p5',
        label: 'PM05 (#)',
        type: 'concentration',
    },
    NPM_15: {
        title: 'PM_1.0',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.COUNT_PER_CM3],
        color: '#FFBF46',
        mappedValue: 'avg_input_num_1p0',
        label: 'PM_1.0 (#)',
        type: 'concentration',
    },
    NPM_25: {
        title: 'PM215',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.COUNT_PER_CM3],
        color: '#C04CFD',
        mappedValue: 'avg_input_num_2p5',
        label: 'PM215 (#)',
        type: 'concentration',
    },
    TVOC: {
        title: 'TVOC',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.TVOC],
        color: '#08b0e7',
        mappedValue: 'avg_input_TVOC',
        label: 'TVOC',
        type: 'concentration',
    },
    CO2: {
        title: 'CO2',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.PPM],
        color: '#F9B115',
        mappedValue: 'avg_input_co2',
        label: 'CO2',
        type: 'concentration',
    },
    //As this will need in future so commenting this code
    // NPM_10: {
    //     title: 'PM210',
    //     symbol: PollutantSymbolMapping[PollutantSymbolByName.COUNT_PER_CM3],
    //     color: '#A53860',
    //     mappedValue: 'avg_input_num_10',
    //     label: 'PM210 (#)',
    //     type: 'concentration',
    // },
};

export const RoomComform: any = {
    TEMPERATURE: {
        title: 'temp',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.DEGREE_CELSIUS],
        color: '#874CEE',
        mappedValue: 'avg_input_temp',
        label: 'temp',
        type: 'room_comfort',
    },
    HUMIDITY: {
        title: 'humidity',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.PERCENTAGE],
        color: '#d5e33b',
        mappedValue: 'avg_input_humidity',
        label: 'humidity',
        type: 'room_comfort',
    },
};

export const AQI: any = {
    title: 'IAQ',
    symbol: 'IAQ',
    color: '#36CB79',
    mappedValue: 'avg_input_aqi',
    label: 'IAQ',
    type: 'IAQ',
};

export const OPERATIONS: any = {
    PressureDrop: {
        title: 'pressure_drop',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.PRESSURE],
        color: '#0E7C7B',
        mappedValue: 'pressure_value',
        label: 'pressure_drop',
        type: 'operations',
    },
    FanSpeed: {
        title: 'fan_speed',
        symbol: PollutantSymbolMapping[PollutantSymbolByName.FANSPEED],
        color: '#F636CC',
        mappedValue: 'fan_speed_value',
        label: 'fan_speed',
        type: 'operations',
    },
};

export const PollutantSymbol: any = {
    CO2: 'PPM',
    'PM1.0': 'ug/m3',
    PM10: 'ug/m3',
    'PM2.5': 'ug/m3',
    TVOC: 'ug/m3',
    humidity: '%',
    temp: '°C',
};

export const DATE_FORMAT = 'MMM DD, YYYY';
export const DATETIME_FORMAT = 'MMM DD, YYYY | hh:mm a';

export enum ORG_TYPES {
    METALMARK = 'metalmark',
    SERVICER = 'servicer',
    CUSTOMER = 'customer',
}

export enum USER_ROLE_ID {
    Superadmin = 1,
    HeadOfCustomerSupport = 2,
    CustomerSatisfactionAdvisor = 3,
    OrganizationAdmin = 4,
    HeadOfOperations = 5,
    FacilityManager = 6,
    ChiefSustainabilityOfficer = 7,
    ServicerAdmin = 8,
    Engineer = 9,
}

export enum LocalStorageKey {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    DEVICE_NAME = 'DEVICE_NAME',
    DEVICE_COUNT = 'DEVICE_COUNT',
    DEVICE_ID = 'DEVICE_ID',
    CHILDNODE = 'CHILDNODE',
    THEME = 'THEME',
    ORG_ID = 'ORG_ID',
}

export enum Theme {
    DARK = 'dark',
    LIGHT = 'light',
}

export enum ThemeVariable {
    BACKGROUND_1 = 'bg-background-01',
    BACKGROUND_2 = 'bg-background-02',
    BACKGROUND_3 = 'bg-background-03',
    TEXT_1 = 'text-t-01',
    TEXT_2 = 'text-t-02',
    TEXT_3 = 'text-t-03',
    BORDER_1 = 'border-br-01',
    BORDER_2 = 'border-br-02',
    BORDER_3 = 'border-br-03',
}

export enum ThemeVariableValues {
    BORDER_1 = '#448eca66',
    BORDER_2 = '#2b597f',
    BORDER_3 = '#aad8fe',
    BORDER_1_LIGHT = '#e6e5e6',
    BORDER_2_LIGHT = '#817b8080',
    BORDER_3_LIGHT = '#448eca',
}

export enum Performance_Benefits {
    RISKLEVELPRODUCTIVITY = 'risklevelproductivity',
    RISKVIRUS = 'riskvirus',
    AQI = 'aqi',
    POLLUTANT = 'pollutant',
}

export const TOOLTIP_ID = 'chartjs-tooltip';

export const PollutantEnum = {
    PM1: 'PM1',
    PM25: 'PM25',
    PM10: 'PM10',
    CO2: 'CO2',
    temp: 'temp',
    humidity: 'humidity',
    TVOC: 'TVOC',
};

// *ChartError
// Calling the method again as there was an issue when loading the first time
// 1. there is an issue for whitescreen, geeting error for minimumFractionDigits value is out of range
// if we add the solution of above it is by adding a callback in tick in y axis and return the value but the
// the solution is not 100% working sometimes its missing the labels and no labels are coming in the y axis
// i.e. we are calling it again to rerender the data.

export enum TemperatureEnum {
    CELSIUS = 'celsius',
    FAHRENHEIT = 'fahrenheit',
}
export const TEMPERATURE = 'TEMPERATURE';

export enum SearchOptions {
    CUSTOMER = 'CUSTOMER',
    SITE = 'SITE',
}

export const NotAllowedSpecialCharacters = [
    '=',
    '+',
    ':',
    '"',
    '<',
    '>',
    '/',
    '~',
    '^',
    '(',
    ')',
    '?',
    '#',
    '$',
    '%',
    '!',
    ';',
    '@',
    "'",
    '*',
    '\\',
    '&',
    '_',
];

export const Lifecycle_State_Keys = [
    'ReadyForUse',
    'Preflight',
    'Decommissioned',
    'InServiceOrRepair',
    'Refurbished',
    'EndOfLife',
];

export const SITE_HEIRARCHY_COMMAND_TEXT = 'Operational Controls';

export const CommandsDropDown = ['Timezone', 'Wifi Reset', 'Device Locator Assist'];
export enum Commandsenum {
    TIMEZONE = 'Timezone',
    WIFIRESET = 'Wifi Reset',
    DEVICELOCATORASSIST = 'Device Locator Assist',
    FILTER_REPLACED = 'FilterReplaced',
    RESTART = 'Restart',
}

export const DeviceModeButtonOptions = ['STANDBY', 'FILTRATION', 'REGEN'];
export const SelfCleaningButtonOptions = ['MANUAL_OVER_RIDE_SELF_RENEW', 'PLANNED_RENEW_CLEANING'];
export const PlannedSelfCleaningButtonOptions = ['EXCLUDE_DEVICES', 'SYNC_SCHEDULE_DEVICES', 'RESET_SCHEDULE_DEVICES'];
export enum DeviceModeButtonOptionsEnum {
    STANDBY = 'Standby',
    FILTERATION = 'Filtration',
    REGEN = 'Regen',
}
export enum AirCleaningButtonOptionsEnum {
    MANUAL_OVER_RIDE = 'Manual_Over_Ride',
    PLANNED_AIR_CLEANING = 'Planned_Air_Cleaning',
}
export enum selfclean {
    SELFCLEAN = 'Self Clean',
}
export enum Regen {
    REGEN = 'REGEN',
}
export enum Runcommandsbutton {
    DEVICEMODE = 'device_mode_button',
    FILTERREPLACEMENT = 'filter_replacement_button',
    AIRCLEANING = 'air_cleaning_level_button',
    WIFIRESET = 'wifi_reset_button',
    TIMEZONE = 'time_zone_button',
    DEVICEREBOOT = 'device_reboot_button',
    SELFCLEANING = 'self_renew_button',
    FIRMWAREUPDATE = 'firmware_update_button',
}

export enum UrlValues {
    DEVICEID = 'device_id',
    ORGID = 'org_id',
}
export enum Devicecommandaction {
    TRUE = 'true',
    FALSE = 'false',
}
export enum ReceivingPollutantDataBooleanValues {
    TRUE = 'True',
    FALSE = 'False',
}
export enum RangeValues {
    STANDBY = 'STANDBY',
    LOW = 'LOW',
    MEDIUM = 'MEDIUM',
    HIGH = 'HIGH',
}
export enum ThingData {
    THING_LIST = 'thing_list',
    THING_NAME = 'thingName',
}
export const TrackValues: { [key: number]: string } = {
    25: 'customSlider-track customSlider-track-25',
    50: 'customSlider-track customSlider-track-50',
    75: 'customSlider-track customSlider-track-75',
};
export const LabelColorSet: { [key: string]: string } = {
    STANDBY: 'time-mode-standby',
    LOW: 'time-mode-low',
    MEDIUM: 'time-mode-medium',
    HIGH: 'time-mode-high',
};
export const CleaningLevelSet: { [key: number]: string } = {
    25: 'Low',
    50: 'Moderate',
    75: 'High',
};
export const MarkLabelSet: { [key: number]: string } = {
    25: RangeValues.LOW,
    50: RangeValues.MEDIUM,
    75: RangeValues.HIGH,
};

export enum FanIconColor {
    WHITE = 'White',
    BLACK = 'black',
}

export const DateButtons = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
export const TimeConventionDropdown = ['AM', 'PM'];

/*below functions will be usefull for logic implementation for Planned Air Cleaning*/

/*This function will return scss class for changing sliders track color based on sliders value */
export const setTrackColor = (state: SliderState) => {
    const className =
        state?.index === 0
            ? TrackValues[state?.value] || 'customSlider-track customSlider-track-0'
            : 'customSlider-track customSlider-track-0';
    return `customSlider-track ${className}`;
};

/*This function will set the Sliders Thumb color to blue if clicked and sliders position at 0 */
export const setThumbColor = (state: SliderThumbState, sliderClicked: boolean) => {
    if (state.valueNow === 0 && sliderClicked) {
        return 'customSlider-mark customSlider-mark-25';
    }
};
/*This function will return the scss class which will changes the slider label color based on values */
export const getMarkLabelColor = (value: number, currentRangeval: number, sliderClicked: boolean) => {
    let labelColor = 'LabelDefault';

    switch (value) {
        case 0:
            if (currentRangeval === 0 && sliderClicked) {
                labelColor = 'LabelBlue';
            }
            break;
        case 25:
            if (currentRangeval === 25) {
                labelColor = 'LabelGreen';
            }
            break;
        case 50:
            if (currentRangeval === 50) {
                labelColor = 'LabelPurple';
            }
            break;
        case 75:
            if (currentRangeval === 75) {
                labelColor = 'LabelRed';
            }
            break;
        default:
            break;
    }

    return labelColor;
};
export const keyToLeftMapping: KeyToLeftMapping = {
    0: 3,
    25: 105,
    50: 209,
    75: 304,
};
export enum DefaultSchedule {
    DEFAULT = 'Default',
}
export enum Schedulelevel {
    LEVEL = 'Level',
}
export enum changePageEvent {
    THINGLISTCHANGED = 'thingsListChanged',
    FIRMWARELISTCHANGES = 'firmwareListChanged',
}
const ZIPFILE_MAX_SIZE = 20 * 1024 * 1024;
const BINFILE_MAX_SIZE = 2 * 1024 * 1024;

export enum FileMaxLengths {
    ZIPFILE = ZIPFILE_MAX_SIZE,
    BINFILE = BINFILE_MAX_SIZE,
}
export enum FileUploadFormdata {
    VERSION_NAME = 'version_name',
    MODEL_NAME = 'model_name',
    DESCRIPTION = 'description',
    FIRMWARE_BINARY_FILE = 'firmware_binary_file',
    OTHER_FILES = 'other_files',
    DESCRIPTION_FILE_NAME = 'description.txt',
    DELETE_OTHER_FILES = 'delete_other_file',
}
export const dayMapping: { [key: string]: number } = {
    mon: 1,
    tue: 2,
    wed: 3,
    thu: 4,
    fri: 5,
    sat: 6,
    sun: 0,
};
export enum descriptionCharLimit {
    MAX_CHAR_LIMIT = 1000,
    MIN_CHAR_LIMIT = 50,
}

export const rangeAliasMap: Record<string, string> = {
    S: 'STANDBY',
    L: 'LOW',
    M: 'MEDIUM',
    H: 'HIGH',
};

export const occuranceAliasMap: Record<string, string> = {
    D: 'Daily',
    W: 'Every Weekday (Mon to Fri)',
    DNR: 'Does not repeat',
    NW: 'Every Weekend',
    E1: 'Every Monday',
    E2: 'Every Tuesday',
    E3: 'Every Wednesday',
    E4: 'Every Thursday',
    E5: 'Every Friday',
    E6: 'Every Saturday',
    E0: 'Every Sunday',
};
export const VERSION_START_LENGTH = 1;
export const VERSION_MINIMUM_LENGTH = 2;
export const FIRMWARE_UPDATE = 'FirmwareUpdate';
export const getSchedulingLevel: { [key: string]: number } = {
    STANDBY: 0,
    LOW: 25,
    MEDIUM: 50,
    HIGH: 75,
};
export enum DeviceType {
    SENSOR = 'Sensor',
    PURIFIER = 'AirPurifier',
}
export enum SelfCleaningButtonOptionsEnum {
    MANUAL_OVER_RIDE = 'Manual_Over_Ride',
    PLANNED_RENEW_CLEANING = 'PLANNED_RENEW_CLEANING',
}
export const daysOfWeekString: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const defaultScheduledData = ['m', 1, 1, 0, 0];
export enum DeviceSuccessEnum {
    ALLOCATE = 'Allocate',
    UNALLOCATE = 'UnAllocate',
    INSTALL = 'Install',
    UNINSTALL = 'UnInstall',
    UNINSTALL_WITH_UNALLOCATE = 'UnInstall_with_unallocate',
}
export enum PlannedAirCleaningValues {
    MODERATE = 'Moderate',
    LOW = 'Low',
    HIGH = 'High',
    UNOCCUPIED = 'Unoccupied',
}
export enum PlannedAirCleaningDivWidthValues {
    MODERATE = '17px',
    LOW = '7px',
    HIGH = '33px',
    UNOCCUPIED = '33px',
}
export enum PlannedAirCleaningColorValues {
    MODERATE = '#726ADF',
    LOW = '#CFCCFF',
    HIGH = '#352F7D',
    UNOCCUPIED = '#081219',
}
export enum SchedularBackgroundColor {
    EMPTY = '#9999991A',
    DARK = '#081219',
    LIGHT = '#EFEEFA',
}
export const SITE_SQFT_AREA_DEFAULT_VALUE = '1000';
export const rangeAliasMaps: Record<string, string> = {
    Unoccupied: 'S',
    Low: 'L',
    Moderate: 'M',
    High: 'H',
};
export const dayMappingReverse: { [key: number]: string } = {
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
    0: 'Sun',
};
export const rangeAliasMapsReverse: Record<string, string> = {
    S: 'Unoccupied',
    L: 'Low',
    M: 'Moderate',
    H: 'High',
};
export const AirCleaningLevelLabelColorSetDark: { [key: string]: string } = {
    Unoccupied: 'time-mode-standby-dark',
    Low: 'time-mode-low-dark',
    Moderate: 'time-mode-medium-dark',
    High: 'time-mode-high-dark',
};
export const AirCleaningLevelLabelColorSetWhite: { [key: string]: string } = {
    Unoccupied: 'time-mode-standby-white',
    Low: 'time-mode-low-white',
    Moderate: 'time-mode-medium-white',
    High: 'time-mode-high-white',
};
export const ManualAirCleanLabel: { [key: string]: string } = {
    HIGH: 'High',
    MEDIUM: 'Moderate',
    LOW: 'Low',
    STANDBY: 'Unoccupied',
};
export const dayNameMapping: { [key: string]: string } = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday',
};
export enum BarDataKeys {
    AVERAGE_PRODUCTIVITY_RISK = 'avg_productivity_risk',
    AVERAGE_VIRUS_RISK = 'avg_virus_risk',
    AVERAGE_INPUT_IAQ = 'avg_input_aqi',
}
export const toasterColorMap = {
    'toaster-success': '#36CB79',
    'toaster-danger': '#F41148',
    'toaster-warning': '#F9B115',
    'toaster-info': '#3399FF',
};
export enum GraphDropdownKeys {
    CONCENTRATION = 'concentration',
    ROOM_COMFORT = 'room_comfort',
    OPERATIONS = 'operations',
}
export const deliveredToCustomer = 'Delivered To Customer';
export const VIEW_USERS = 'Users';
export const DEFAULT_TIMEZONE = 'America/New_York';
export const GraphDifferenceValue = 10;

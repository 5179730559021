export const DEVICE_REQUEST = 'DEVICE_REQUEST';
export const DEVICE_REQUEST_APPEND = 'DEVICE_REQUEST_APPEND';
export const DEVICE_REQUEST_SUCCESS = 'DEVICE_REQUEST_SUCCESS';
export const DEVICE_REQUEST_APPEND_SUCCESS = 'DEVICE_REQUEST_APPEND_SUCCESS';
export const DEVICE_REQUEST_FAILURE = 'DEVICE_REQUEST_FAILURE';
export const SINGLE_DEVICE_REQUEST = 'SINGLE_DEVICE_REQUEST';
export const SINGLE_DEVICE_REQUEST_SUCCESS = 'SINGLE_DEVICE_REQUEST_SUCCESS';
export const SINGLE_DEVICE_REQUEST_FAILURE = 'SINGLE_DEVICE_REQUEST_FAILURE';
export const SINGLE_DEVICE_GRAPH_REQUEST = 'SINGLE_DEVICE_GRAPH_REQUEST';
export const SINGLE_DEVICE_GRAPH_REQUEST_SUCCESS = 'SINGLE_DEVICE_GRAPH_REQUEST_SUCCESS';
export const SINGLE_DEVICE_GRAPH_REQUEST_FAILURE = 'SINGLE_DEVICE_GRAPH_REQUEST_FAILURE';
export const GET_ALL_DEVICES = 'GET_ALL_DEVICES';
export const GET_ALL_DEVICES_SUCESS = 'GET_ALL_DEVICES_SUCESS';
export const GET_ALL_DEVICES_FAILURE = 'GET_ALL_DEVICES_FAILURE';
export const SINGLE_DEVICE_CUSTOM_RANGE_REQUEST = 'SINGLE_DEVICE_CUSTOM_RANGE_REQUEST';
export const SINGLE_DEVICE_CUSTOM_RANGE_SUCCESS = 'SINGLE_DEVICE_CUSTOM_RANGE_SUCCESS';
export const SINGLE_DEVICE_CUSTOM_RANGE_FAILURE = 'SINGLE_DEVICE_CUSTOM_RANGE_FAILURE';

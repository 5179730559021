import { onLoginAPI, onLogoutAPI } from 'api/AuthAPI';
import { all, call, Effect, put, takeLatest } from 'redux-saga/effects';
import {
    loginRequestFailure,
    loginRequestSuccess,
    logoutRequestFailure,
    setToastAction,
    userRequest,
} from 'redux/actions';
import { LoginResponse } from 'redux/types/authActionModel';
import * as actionTypes from '../actionTypes';
import { logoutService } from 'shared/service/CommonService';
import { ToastVariant } from 'shared/utils/Constants';

/*
  Worker Saga: Fired on LOGIN_REQUEST action
*/
function* fetchLoginSaga(action: Effect) {
    try {
        const response: LoginResponse = yield call(onLoginAPI, action.payload);
        yield put(loginRequestSuccess(response));
        yield put(userRequest());
    } catch (e: any) {
        yield put(
            loginRequestFailure({
                error: e?.response?.data,
            }),
        );
    }
}

function* fetchLogoutSaga() {
    try {
        const response: string = yield call(onLogoutAPI);
        logoutService();
        yield put(setToastAction(ToastVariant.SUCCESS, response));
    } catch (e: any) {
        yield put(logoutRequestFailure({ error: e?.response?.data }));
    }
}

/*
  Starts worker saga on latest dispatched `LOGIN_REQUEST` action.
*/
function* authSaga() {
    yield all([takeLatest(actionTypes.LOGIN_REQUEST, fetchLoginSaga)]);
    yield all([takeLatest(actionTypes.LOGOUT_REQUEST, fetchLogoutSaga)]);
}

export default authSaga;
